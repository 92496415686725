<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link
          class="nav-link"
          :to="{ name: 'home' }"
          role="button"
          target="_blank"
          title="Ir al inicio"
        >
          <font-awesome-icon icon="home" />
        </router-link>
      </li>
      <!--
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <span class="d-block d-sm-none">XS</span>
          <span class="d-none d-sm-block d-md-none">SM</span>
          <span class="d-none d-md-block d-lg-none">MD</span>
          <span class="d-none d-lg-block d-xl-none">LG</span>
          <span class="d-none d-xl-block d-xxl-none">XL</span>
          <span class="d-none d-xxl-block">XXL</span>
        </a>
      </li>
      -->
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item d-sm-block d-md-none">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button" @click.prevent="$parent.toggleMenu()">
          <font-awesome-icon icon="bars" />
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          role="button"
          title="Salir"
        >
          <font-awesome-icon icon="sign-out-alt" @click="logout()" />
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
  
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem('user')
      this.$router.push({ name: 'admin.login' })
    }
  }
}
</script>