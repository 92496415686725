
import Header from './Header.vue'
import Footer from './Footer.vue'
import { defineComponent } from "vue"
import { IonPage, IonContent } from '@ionic/vue';
import { ActiveRecordService } from '../../../helpers/active_record';
import { settings, sleep } from '../../../services/settings.service';
import { questions } from '../../../services/faq.service';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: 'PublicLayout',
  data() {
    return {
      searchTitle: "",
      hideChat: true,
      hideChatButton: true,
      bg: '',
      model: new ActiveRecordService(),
      settings: settings,
      social: new Array<any>(),
      questions: questions,
      messages: new Array(),
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  components: {
    'Footer': Footer,
    'Header': Header,
    IonPage,
    IonContent,
  },
  methods: {
    loading(boolean: boolean) {
      this.$emit('loading', boolean)
    },
    setLayout(layout: string) {
      this.$emit('layout', layout)
    },
    setBg(value: string) {
      this.bg = value;
    },
    closeChat() {
      this.hideChat = true;
      this.hideChatButton = false;
    },
    openChat() {
      this.hideChat = false;
      this.hideChatButton = true;
    },
    clearChat() {
      this.messages = this.messages.filter(item => item.type == "QUESTION")
    },
    reply(id: number) {
      this.clearChat();
      this.messages.push({
        type: "REPLY",
        message: {
          response: "...",
        }
      });
      this.questions.get(id).then(question => {
        sleep(500).then(_ => {
          this.clearChat();

          this.messages.push({
            message: question.attributes,
            type: "REPLY"
          });
        })
      });
    },
    search() {
      if (!this.searchTitle) {
        return;
      }
      this.clearChat();
      const searchTitle = this.searchTitle;
      this.searchTitle = '';

      this.messages.push({
        message: {
          title: searchTitle
        },
        type: "SEARCH",
      });

      this.model
        .from('articles')
        .where("type", "=", "product")
        .where("status", "=", "1")
        .where("soundex", "=", searchTitle)
        .orderBy("title")
        .all((items: any[]) => {
          if (this.model.items.length > 0) {
            this.model.items.map(item => {
              this.messages.push({
                message: {
                  title: item.attributes.title,
                  slug: item.attributes.slug,
                  id: item.attributes.id,
                },
                type: "ARTICLE",
              });
            });
          } else {
            this.messages.push({
              message: {
                response: "Disculpe, el medicamento que busca no se encuentra disponible, por favor intente con otra búsqueda."
              },
              type: "REPLY"
            });
          }
        });
    }
  },
  mounted() {
    document.title = "SUAF - Farmacias Móviles";

    this.social = [];

    for (const id of [8, 9, 10]) {
      this.settings.get(id).then((setting: any) => {
        if (setting && setting.value) {
          this.social.push(setting)
        }
      })
    }

    this.questions.all()
      .then(items => {
        this.messages = items.map(item => {
          return { message: item.attributes, type: "QUESTION" };
        });
        this.hideChatButton = false;
      })

  }
})
