
import PublicLayout from './components/public/layouts/PublicLayout.vue'
import AdminLayout from './components/admin/layout/AdminLayout.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { pushInit } from './services/push_notification'

const __default__ = defineComponent({
  components: {
    PublicLayout,
    AdminLayout,
  },
  data () {
    return {
      isLoading: false,
      bg: {
        home: `url(${process.env.VUE_APP_API_URL}/public/files/image-home)`,
        about: `url(${process.env.VUE_APP_API_URL}/public/files/image-about)`,
        products: `url(${process.env.VUE_APP_API_URL}/public/files/image-products)`,
        sites: `url(${process.env.VUE_APP_API_URL}/public/files/image-sites)`,
        blog: `url(${process.env.VUE_APP_API_URL}/public/files/image-blog)`,
        contact: `url(${process.env.VUE_APP_API_URL}/public/files/image-contact)`,
      }
    }
  },
  methods: {
    setSpinner(value: boolean) {
      this.isLoading = value;
    },
    setLayout(layout: string) {
      this.layout = layout;
    },
    toggleMenu() {
      document.body.classList.toggle('sidebar-open');
    }
  },
  mounted() {
    pushInit()
    //this.layout = (useRoute().meta?.layout as string) || 'public';
  },
  computed: {
    // a computed getter
    layout: function () {
      return (useRoute().meta?.layout as string) || 'public';
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7c36e1f4": (_ctx.bg.home),
  "0a3cd918": (_ctx.bg.about),
  "7ac9a80e": (_ctx.bg.products),
  "0b3dbf97": (_ctx.bg.sites),
  "7c341cb7": (_ctx.bg.blog),
  "2350ba2a": (_ctx.bg.contact)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__