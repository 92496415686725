
import Navbar from './Navbar.vue'
import Sidebar from './Sidebar.vue'
import Footer from './Footer.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AdminLayout',
  components: {
    Navbar,
    Sidebar,
    Footer
  },
  data () {
    return {
      //
    }
  },
  methods: {
    loading(boolean: boolean) {
      this.$emit('loading', boolean)
    },
    toggleMenu() {
      document.body.classList.toggle('sidebar-open')
    }
  },
  mounted () {
    if (!localStorage.user) {
      this.$router.push({ name: 'admin.login' })
    }

    document.title = "Administrador - Catálogo"
  }
})
