
import { defineComponent } from "vue";
import { ActiveRecordService } from "../../helpers/active_record";
import Sidebar from '../../components/public/layouts/Sidebar.vue';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  components : {
    'Sidebar': Sidebar
  },
  data() {
    return {
      model: new ActiveRecordService(),
      categories: new ActiveRecordService(),
      title: 'Medicinas',
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
      downloadLink: `${process.env.VUE_APP_API_URL}/api/articles/download`,
      apkLink: "https://play.google.com/store/apps/details?id=io.suaf.catalogo",
    }
  },
  mounted () {
    this.$emit('background', 'bg-none')

    this.categories
      .from('categories')
      .where('type', '=', 'product')
      .orderBy('name', 'ASC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .paginate(0)
      .all()

    this.model
      .from('articles')
      .where('type', '=', 'product')
      .where('status', '=', '1')
      .orderBy('title', 'ASC')
      .relations(['images', 'category'])
      .where('soundex', '=', this.$route.query.title as string || '')
      .where('category_id', '=', this.$route.query.category_id as string || '')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })

      if (!this.isWeb) {
        this.model
          .paginate(30)
          .all()
        ;
      } else {
        this.model
          .paginate(12)
          .all()
        ;

      }
  },
})
