
import { defineComponent } from 'vue'
import { ActiveRecordService } from '../../../helpers/active_record'

export default defineComponent({
  data () {
    return {
      model: new ActiveRecordService(),
    }
  },
  mounted () {
    this.$emit('loading', true)

    this.model
      .from('questions')
      .orderBy('id', 'DESC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .all()
  },
  methods: {
    remove(item: ActiveRecordService) {
      if (!confirm("¿Está seguro(a) que desea eliminar este registro?")) {
        return;
      }

      this.$emit('loading', true)

      item.delete(() => {
        this.model.all()
      })
    },
    getSortIcon(name: string) {
      if (this.model.sort.column == name) {
        return {
          prefix: 'fas',
          iconName: this.model.sort.order == 'ASC' ? 'sort-up' : 'sort-down',
        }
      }

      return {
        prefix: 'fas',
        iconName: 'sort'
      }
    }
  },
})
