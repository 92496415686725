import { ActiveRecordService } from '@/helpers/active_record';

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms, [ms]);
    });
}

class FAQService {
    public questions: any = {}
    public model = new ActiveRecordService();
    protected isReady = false

    public constructor() {
        this.init();
    }

    async init() {
        // await Storage.get({ key: 'questions' })
        //     .then((data) => {

        //     if (data.value) {
        //         this.questions = JSON.parse(data.value)
        //    } else {
                this.model
                    .from('questions')
                    .paginate(0)
                    .all(async (response: any) => {
                        // await Storage.set({
                        //     key: 'questions',
                        //     value: JSON.stringify(response.data.data),
                        // });

                        this.isReady = true

                        this.questions = response.data.data
                    })
        //    }
        //})
    }

    get(id: number): Promise<any> {
        return new Promise(async (resolve, reject) => {
            while (!this.isReady) {
                await sleep(100);
            }
    
            resolve(this.model.items.find((item: ActiveRecordService) => item.attributes.id == id));
        })
    }

    async all(): Promise<any[]> {
        while (!this.isReady) {
            await sleep(100);
        }

        return this.model.items;
    }
}

export const questions = new FAQService()
