<template>
  <div id="about-us" class="container-fluid position-relative pb-5 bg-light">
    <div class="container">
      <div class="row px-md-5 gx-md-5 pb-5">
        <h1 id="content-title" class="text-main pb-5">{{ title }}</h1>
      </div>

      <div class="row">
        <div class="col-ms-12">
          <p class="fs-1 text-main">{{ history.attributes.value }}</p>
          <div v-html="history.attributes.description"></div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-md-4 p-sm-5  px-md-5"
          v-for="setting in settings.items"
          :key="setting.attributes.id"
        >
          <p class="fs-1 text-center text-main">{{ setting.attributes.value }}</p>
          <div v-html="setting.attributes.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ActiveRecordService } from "../../helpers/active_record";
import { isPlatform } from '@ionic/vue';

export default {
  data () {
    return {
      title: '¿Quienes somos?',
      settings: new ActiveRecordService(),
      history: new ActiveRecordService(),
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  mounted () {
    this.$emit('background', 'bg-none')

    this.$emit('loading', false)

    this.settings
      .from('settings')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .whereIn('id', ['14', '15', '16'])
      .orderBy('id', 'ASC')
      .all()

    this.history
      .from('settings')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .load(17)
  }
}
</script>
