
import axios, { AxiosResponse } from "axios"
import { defineComponent } from "vue"
import { ActiveRecordService } from '../../../helpers/active_record'
import { Env } from '../../../services/env.service'

export default defineComponent({
  data () {
    return {
      roles: [],
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        email: undefined,
        password: undefined,
        password_confirmation: undefined,
        role_ids: [],
      })
    }
  },
  mounted () {
    this.model
      .from('users')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter((response: AxiosResponse<any>) => {
        this.$emit('loading', false)

        this.model.attributes.role_ids = response.data.roles.map((role: any) => role.id)
      })

    axios.get(`${Env.get('VUE_APP_API_URL')}/api/roles?per_page=0`)
      .then((response: AxiosResponse<any>) => {
        this.roles = response.data.data

        if (this.$route.params.id) {
          this.model
            .relations(['roles'])
            .load(this.$route.params.id)
        } else {
          this.$emit('loading', false)
        }
      })
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success: CallableFunction;

      if (this.model.isNew) {
        success = (response: AxiosResponse<any>) => {
          this.$router.push({ name: 'admin.access.users.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response: AxiosResponse<any>) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  }
})
