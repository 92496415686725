
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      moment: moment,
    }
  },
})
