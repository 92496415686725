
import { AxiosResponse } from "axios"
import Editor from '@tinymce/tinymce-vue'
import { defineComponent } from 'vue'
import { ActiveRecordService } from '../../../helpers/active_record'
import { Auth } from "../../../services/auth.service"

export default defineComponent({
  components: {
    Editor
  },
  data () {
    return {
      model: new ActiveRecordService({
        id: undefined,
        title: undefined,
        content: undefined,
        status: undefined,
        category_id: undefined,
        images: [],
        file: undefined,
        highlighted: 'false',
        type: 'news',
        user_id: Auth.user?.id,
        // user_id: 1,
      }),
      categories: new ActiveRecordService()
    }
  },
  mounted () {
    this.model
      .from('articles')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .relations(['category', 'images'])

    this.categories
      .from('categories')
      .where('type', '=', 'news')
      .orderBy('name', 'ASC')
      .paginate(0)
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        if (this.$route.params.id) {
          this.model
            .load(this.$route.params.id)
        } else {
          this.$emit('loading', false)
        }
      })
      .all()
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success: CallableFunction;

      if (this.model.isNew) {
        success = (response: AxiosResponse<any>) => {
          const id = response.data[this.model.primaryKey];

          this.model.load(id)

          this.$router.push({ name: 'admin.news.update', params: { id: id } })
          this.$emit('loading', false)
        }
      } else {
        success = (response: AxiosResponse<any>) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.saveFormData(success, () => {
        this.$emit('loading', false)
      })
    }
  }
})
