
import axios, { AxiosResponse } from "axios"
import Editor from '@tinymce/tinymce-vue'
import { defineComponent } from 'vue'
import { ActiveRecordService } from '../../../helpers/active_record'
import { Auth } from "../../../services/auth.service"
import { Env } from '../../../services/env.service'

export default defineComponent({
  components: {
    Editor
  },
  data() {
    return {
      model: new ActiveRecordService({
        id: undefined,
        title: undefined,
        content: undefined,
        status: undefined,
        price: undefined,
        category_id: undefined,
        images: [],
        file: undefined,
        highlighted: 'false',
        type: 'product',
        user_id: Auth.user?.id,
        // user_id: 1,
        tag_ids: [],
      }),
      categories: new ActiveRecordService(),
      new: new ActiveRecordService(),
      tags: [],
    }
  },
  mounted() {
    this.model
      .from('articles')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter((response: AxiosResponse<any>) => {
        this.model.attributes.tag_ids = response.data.tags.map((tag: any) => tag.id)
        this.$emit('loading', false)
      })
      .relations(['category', 'images', 'tags']);


    axios.get(`${Env.get('VUE_APP_API_URL')}/api/tags?per_page=0`)
      .then((response: AxiosResponse<any>) => {
        this.tags = response.data.data;

        this.categories
          .from('categories')
          .where('type', '=', 'product')
          .orderBy('name', 'ASC')
          .paginate(0)
          .setBefore(() => {
            this.$emit('loading', true)
          })
          .setAfter(() => {
            if (this.$route.params.id) {
              this.model
                .load(this.$route.params.id)
            } else {
              this.$emit('loading', false)
            }
          })
          .all()
      })
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success: CallableFunction;

      if (this.model.isNew) {
        success = (response: AxiosResponse<any>) => {
          const id = response.data[this.model.primaryKey];

          this.model.load(id)

          this.$router.push({ name: 'admin.articles.update', params: { id: id } })
          this.$emit('loading', false)
        }
      } else {
        success = (response: AxiosResponse<any>) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.saveFormData(success, () => {
        this.$emit('loading', false)
      })
    }
  }
})
