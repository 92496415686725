
import axios, { AxiosResponse } from "axios"
import { defineComponent } from "vue"
import { ActiveRecordService } from '../../../helpers/active_record'
import { Env } from "../../../services/env.service"

export default defineComponent({
  data () {
    return {
      permissions: [],
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        type: 'articulo',
        description: undefined,
        permission_ids: [],
      }),
      actions: {
        "create": "Crear",
        "read": "Ver",
        "update": "Actualizar",
        "delete": "Eliminar",
      },
      modules: {
        "users": "Usuarios",
        "roles": "Roles",
        "permissions": "Permisos",
        "articles": "Medicinas",
        "categories": "Categorías",
        "news": "Noticias",
        "states": "Estados",
        "organizations": "Instituciones",
        "sites": "Sedes",
        "contacts": "Contactos",
        "settings": "Parámetros de Sistema"
      },
    }
  },
  mounted () {
    this.model
      .from('roles')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter((response: AxiosResponse<any>) => {
        this.$emit('loading', false)

        this.model.attributes.permission_ids = response.data.permissions.map((permission: any) => permission.id)
      })

    axios.get(`${Env.get('VUE_APP_API_URL')}/api/permissions?per_page=0`)
      .then((response: AxiosResponse<any>) => {
        this.permissions = response.data.data

        if (this.$route.params.id) {
          this.model
            .relations(['permissions'])
            .load(this.$route.params.id)
        } else {
          this.$emit('loading', false)
        }
      })
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success: CallableFunction;

      if (this.model.isNew) {
        success = (response: AxiosResponse<any>) => {
          this.$router.push({ name: 'admin.access.roles.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response: AxiosResponse<any>) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    },
    getPermissionName(str: string) {
      const strArray = str.split("_");

      
      return `${(this.actions as any)[strArray[0]]} ${(this.modules as any)[strArray[1]]}`;
    }
  }
})
