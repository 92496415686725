
import { AxiosResponse } from 'axios'
import { defineComponent } from 'vue'
import { ActiveRecordService } from '../../../helpers/active_record'

export default defineComponent({
  data () {
    return {
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        description: undefined,
        permission_ids: [],
      })
    }
  },
  mounted () {
    this.model
      .from('tags')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })

      if (this.$route.params.id) {
        this.model
          .load(this.$route.params.id)
      }
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success: CallableFunction;

      if (this.model.isNew) {
        success = (response: AxiosResponse<any>) => {
          this.$router.push({ name: 'admin.tags.update', params: { id: response.data[this.model.primaryKey] } })
          this.$emit('loading', false)
        }
      } else {
        success = (response: AxiosResponse<any>) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  },
})
