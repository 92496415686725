import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56718946"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar),
    _createVNode(_component_Sidebar),
    (_openBlock(), _createBlock(_component_router_view, {
      onLoading: _ctx.loading,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMenu())),
      key: _ctx.$route.fullPath
    }, null, 8, ["onLoading"])),
    _createVNode(_component_Footer)
  ]))
}