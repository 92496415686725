import { ActiveRecordService } from '@/helpers/active_record';

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, ms, [ms]);
    });
}

class SettingsService {
    public settings: any = {}
    public model = new ActiveRecordService();
    protected isReady = false

    public constructor() {
        this.init();
    }

    async init() {
        // await Storage.get({ key: 'settings' })
        //     .then((data) => {

        //     if (data.value) {
        //         this.settings = JSON.parse(data.value)
        //    } else {
                this.model
                    .from('settings')
                    .paginate(0)
                    .all(async (response: any) => {
                        // await Storage.set({
                        //     key: 'settings',
                        //     value: JSON.stringify(response.data.data),
                        // });

                        this.isReady = true

                        this.settings = response.data.data
                    })
        //    }
        //})
    }

    get(id: number): Promise<any> {
        return new Promise(async (resolve, reject) => {
            while (!this.isReady) {
                await sleep(100);
            }
    
            const items = this.model.items.filter((item: ActiveRecordService) => item.attributes.id == id)[0]
    
            resolve(items.attributes)
        })
    }
}

export const settings = new SettingsService()
