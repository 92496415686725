import {
  //createRouter,
  //createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
  NavigationGuardWithThis,
  RouteLocation
} from 'vue-router'
import { createRouter, createWebHistory } from '@ionic/vue-router';
import PublicHome from '@/views/public/Home.vue'
import PublicMobileHome from '@/views/public/HomeMobile.vue'
import Articles from '@/views/public/Articles.vue'
import PublicNotFound from '@/views/public/NotFound.vue'
import Login from '@/views/public/Login.vue'
import Article from '@/views/public/Article.vue'
import Sites from '@/views/public/Sites.vue'
import ContactUs from '@/views/public/ContactUs.vue'
import News from '@/views/public/News.vue'
import About from '@/views/public/About.vue'
import Policy from '@/views/public/Policy.vue'


import AdminHome from '@/views/admin/Home.vue'

//import AdminNotFound from '@/views/admin/NotFound.vue'
import ArticlesList from '@/views/admin/articles/List.vue'
import ArticlesForm from '@/views/admin/articles/Form.vue'
import NewsList from '@/views/admin/news/List.vue'
import NewsForm from '@/views/admin/news/Form.vue'
import CategoryList from '@/views/admin/category/List.vue'
import CategoryForm from '@/views/admin/category/Form.vue'
import TagList from '@/views/admin/tag/List.vue'
import TagForm from '@/views/admin/tag/Form.vue'
import StateList from '@/views/admin/state/List.vue'
import StateForm from '@/views/admin/state/Form.vue'
import OrganizationList from '@/views/admin/organization/List.vue'
import OrganizationForm from '@/views/admin/organization/Form.vue'
import SiteList from '@/views/admin/site/List.vue'
import SiteForm from '@/views/admin/site/Form.vue'

import UserList from '@/views/admin/users/List.vue'
import UserForm from '@/views/admin/users/Form.vue'
import RolesList from '@/views/admin/roles/List.vue'
import RolesForm from '@/views/admin/roles/Form.vue'
import PermissionsList from '@/views/admin/permissions/List.vue'

import SettingsList from '@/views/admin/settings/List.vue'
import SettingsForm from '@/views/admin/settings/Form.vue'
import Internal from '@/views/admin/settings/Internal.vue'
import ContactsList from '@/views/admin/contacts/List.vue'
import ContactsForm from '@/views/admin/contacts/Form.vue'
import PreguntasList from '@/views/admin/faq/List.vue'
import PreguntasForm from '@/views/admin/faq/Form.vue'
import ImagesList from '@/views/admin/images/List.vue'
import { isPlatform } from '@ionic/vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'start',
    beforeEnter: (to: RouteLocation, from: any, next: any): any => {
      if (isPlatform('hybrid') || isPlatform('mobileweb')) {
        return next("/productos")
      }

      return next()
    },
    component: PublicHome,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/inicio',
    name: 'home',
    component: PublicHome,
    meta: {
      layout: 'public'
    },
    beforeEnter: (to: RouteLocation, from: any, next: any): any => {
      if (isPlatform('hybrid') || isPlatform('mobileweb')) {
        return next("/inicio/mb")
      }

      return next()
    },
  },
  {
    path: '/inicio/mb',
    name: 'home.mb',
    component: PublicMobileHome,
    meta: {
      layout: 'public'
    },
  },
  {
    path: '/productos',
    name: 'article.index',
    component: Articles,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/productos/:id',
    name: 'article.details2',
    component: Article,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/productos/:id/:slug',
    name: 'article.details',
    component: Article,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/farmacias',
    name: 'sites.index',
    component: Sites,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/contacto',
    name: 'contact.index',
    component: ContactUs,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/blog',
    name: 'news.index',
    component: News,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/blog/:id/:slug',
    name: 'news.details',
    component: Article,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/nosotros',
    name: 'about.index',
    component: About,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/politicas',
    name: 'policies.index',
    component: Policy,
    meta: {
      layout: 'public'
    }
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    component: Login,
    meta: {
      layout: 'public',
      guard: 'guest',
    }
  },
  {
    path: '/admin',
    name: 'admin.home',
    redirect: { name: 'admin.articles.index' },
    // component: AdminHome,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/articles',
    name: 'admin.articles.index',
    component: ArticlesList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/articles/create',
    name: 'admin.articles.create',
    component: ArticlesForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/articles/:id/update',
    name: 'admin.articles.update',
    component: ArticlesForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/news',
    name: 'admin.news.index',
    component: NewsList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/news/create',
    name: 'admin.news.create',
    component: NewsForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/news/:id/update',
    name: 'admin.news.update',
    component: NewsForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/categories',
    name: 'admin.categories.index',
    component: CategoryList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/categories/create',
    name: 'admin.categories.create',
    component: CategoryForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/categories/:id/update',
    name: 'admin.categories.update',
    component: CategoryForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },

  
  {
    path: '/admin/tags',
    name: 'admin.tags.index',
    component: TagList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/tags/create',
    name: 'admin.tags.create',
    component: TagForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/tags/:id/update',
    name: 'admin.tags.update',
    component: TagForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },


  {
    path: '/admin/states',
    name: 'admin.states.index',
    component: StateList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/states/create',
    name: 'admin.states.create',
    component: StateForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/states/:id/update',
    name: 'admin.states.update',
    component: StateForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/organizations',
    name: 'admin.organizations.index',
    component: OrganizationList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/organizations/create',
    name: 'admin.organizations.create',
    component: OrganizationForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/organizations/:id/update',
    name: 'admin.organizations.update',
    component: OrganizationForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/sites',
    name: 'admin.sites.index',
    component: SiteList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/sites/create',
    name: 'admin.sites.create',
    component: SiteForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/sites/:id/update',
    name: 'admin.sites.update',
    component: SiteForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/users',
    name: 'admin.access.users.index',
    component: UserList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/users/create',
    name: 'admin.access.users.create',
    component: UserForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/users/:id/update',
    name: 'admin.access.users.update',
    component: UserForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/roles',
    name: 'admin.access.roles.index',
    component: RolesList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/roles/create',
    name: 'admin.access.roles.create',
    component: RolesForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/roles/:id/update',
    name: 'admin.access.roles.update',
    component: RolesForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/access/permissions',
    name: 'admin.access.permissions.index',
    component: PermissionsList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/settings/',
    name: 'admin.settings.index',
    component: SettingsList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/settings/:id/update',
    name: 'admin.settings.update',
    component: SettingsForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/settings/:slug/',
    name: 'admin.settings.internal',
    component: Internal,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/contacts/',
    name: 'admin.contacts.index',
    component: ContactsList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/contacts/:id/update',
    name: 'admin.contacts.update',
    component: ContactsForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/images/',
    name: 'admin.images.index',
    component: ImagesList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },


  {
    path: '/admin/preguntas',
    name: 'admin.preguntas.index',
    component: PreguntasList,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/preguntas/create',
    name: 'admin.preguntas.create',
    component: PreguntasForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },
  {
    path: '/admin/preguntas/:id/update',
    name: 'admin.preguntas.update',
    component: PreguntasForm,
    meta: {
      layout: 'admin',
      guard: 'guest',
    }
  },



  {
    path: '/:pathMatch(.*)*',
    name: 'public.not_found',
    component: PublicNotFound,
    meta: {
      layout: 'public'
    }
  },
  /*
  {
    path: '/admin/:pathMatch(.*)*',
    name: 'admin.not_found',
    component: AdminNotFound,
    meta: {
      layout: 'admin'
    }
  }
  */
]

const guard: NavigationGuardWithThis<undefined> = function(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  next()
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(guard)

export default router
