
import { defineComponent } from "vue"
import { IonPage, IonContent, isPlatform } from '@ionic/vue';
import { ActiveRecordService } from "../../helpers/active_record";
import { pushInit } from "@/services/push_notification";

export default defineComponent({
  components : {
    IonPage,
    IonContent,
  },
  data() {
    return {
      apkLink: "https://play.google.com/store/apps/details?id=io.suaf.catalogo",
      downloadLink: `${process.env.VUE_APP_API_URL}/api/articles/download`,
      images: new Array<string>(),
      counter: 0,
      timeout: 0,
      model: new ActiveRecordService(),
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  mounted () {
    if (this.isWeb) {
      this.$emit('background', 'bg-none')
    } else {
      pushInit();

      this.$emit('background', 'bg-mobile')
    }

    this.model
      .from("slider")
      .all(() => {
        for (const file of this.model.items) {
          this.images.push(file.attributes.path)
        }
      })
    ;
    
    if (!isPlatform('hybrid') && !isPlatform('mobileweb')) {
      this.carousel();
      document.getElementById("main-container")?.classList.add("bg-img-home");
    } else {
      document.getElementById("main-container")?.classList.add("bg-img-home", "movil");
      document.getElementById("slider")?.classList.add("d-none");
    }
  },
  methods: {
    carousel() {
      this.timeout = setTimeout(() => {
        this.rotate();

        this.nextCounter();
        this.carousel();

      }, 5000)
    },
    rotate() {
      const images = document.getElementsByClassName("slider-img");

      for (var i = 0; i < this.images.length; i++) {
        images.item(i)?.classList?.add("hide");
      }

      document.getElementById(`img-${this.counter}`)?.classList?.remove('hide');
      document.getElementById(`img-${this.counter}`)?.classList?.add('show');
    },
    resetTimeout() {
      clearTimeout(this.timeout);
    },
    nextCounter() {
      this.counter++

      if (this.counter >= this.images.length) {
        this.counter = 0;
      }
    },
    prevCounter() {
      this.counter--

      if (this.counter < 0) {
        this.counter = this.images.length - 1;
      }
    },
    next() {
      this.resetTimeout();
      this.nextCounter();
      this.rotate();
      this.carousel();
    },
    prev() {
      this.resetTimeout();
      this.prevCounter();
      this.rotate();
      this.carousel();
    }
  },
  unmounted() {
    this.resetTimeout()
  }
})
