import axios from "axios"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { Env } from "./env.service"

export type LoginCredentials = {
  email: string;
  password: string;
}

export type UserData = {
  user: any;
  token: string;
  refresh_token: string;
}

export class Auth {
  private static USER_DATA_NAME = 'USER_DATA';
  private static LOGIN_ROUTE_NAME = 'admin.login';
  private static ADMIN_ROUTE_NAME = 'admin.home';
  public static errors: {[key: string]: string[]} = {}

  public static login(credentials: UserData) {
    Auth.errors = {};
    //return new Promise((resolve, reject) => {
      axios.post(`${Env.get('VUE_APP_API_URL')}/api/login`, credentials)
        .then((response) => {

          Auth.data = response.data as UserData;

        }, (error) => {
          Auth.errors = error.response.data.errors;
        })
        .catch( (error) => {
          Auth.errors = error.response
        })
    //})
  }

  public static logout(): void {
    localStorage.removeItem(Auth.USER_DATA_NAME);
  }

  public static get data(): UserData {
    return JSON.parse(localStorage.user ?? '{}');
  }

  public static set data(data: UserData) {
    localStorage.setItem(Auth.USER_DATA_NAME, JSON.stringify(data));
  }

  public static isGuest(): boolean {
    return false;
    return Auth.data?.user ? false : true;
  }

  public static get user() {
    return this.data.user;
  }

  public static guard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    next({ name: Auth.ADMIN_ROUTE_NAME});
    return;
    if (to.meta.guard == 'guest' && !Auth.isGuest()) {
      next({ name: Auth.ADMIN_ROUTE_NAME});
    } else if (to.meta.guard == 'access' && Auth.isGuest()) {
      next({ name: Auth.LOGIN_ROUTE_NAME});
    } else {
      next();
    }
  }

  static hasPermission(name: string | string[]) {
    return true;
    if (typeof name === "string") {
      return Auth.data.user?.permissions.filter((perm: string) => perm == name).length > 0
    }

    return Auth.data.user?.permissions.filter((perm: string) => name.includes(perm)).length > 0
  }
}
