
import axios from 'axios'
import { defineComponent } from 'vue'
import { ActiveRecordService } from '../../../helpers/active_record'

export default defineComponent({
  data () {
    return {
      model: new ActiveRecordService(),
      image: undefined,
      images: new Array<File>()
    }
  },
  mounted () {
    this.$emit('loading', true)

    this.model
      .from('settings')
      .whereIn('id', ['18', '19', '20', '21', '22', '23', '24'])
      .orderBy('id', "ASC")
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .all()
  },
  methods: {
    addFile(item: ActiveRecordService, file: File) {
      item.attributes.file = file;
    },
    addFiles(files: File[]) {
      this.images = files;
    },
    save(item: ActiveRecordService) {
      this.$emit('loading', true)

      item.saveFormData(() => {
        window.location.reload();
        this.$emit('loading', false)
      }, () => {
        this.$emit('loading', false)
      })
    },
    saveSlider() {
      this.$emit('loading', true);

      const formData = new FormData();

      for (const [index, file] of Object.entries(this.images)) {
        formData.append(`files[${index}]`, file)
      }

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/slider`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then(() => {
          window.location.reload();
          this.$emit('loading', false)
        }).catch(error => {
          this.$emit('loading', false)
        })
    },
    getSortIcon(name: string) {
      if (this.model.sort.column == name) {
        return {
          prefix: 'fas',
          iconName: this.model.sort.order == 'ASC' ? 'sort-up' : 'sort-down',
        }
      }

      return {
        prefix: 'fas',
        iconName: 'sort'
      }
    },
  },
})
