
import { defineComponent } from "vue"
import { IonPage, IonContent, isPlatform } from '@ionic/vue';
import { ActiveRecordService } from "../../helpers/active_record";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
  },
  data() {
    return {
      highlighted: new ActiveRecordService(),
      medicinas: new ActiveRecordService(),
      articulos: new ActiveRecordService(),
      slider: new Array<ActiveRecordService>(),
      destacados: new Array<ActiveRecordService>(),
      counter: 1,
      timeout: 0,
    }
  },
  mounted() {
    this.highlighted
      .from("articles")
      .where("highlighted", "=", "true")
      .where("type", "=", "news")
      .orderBy("title", "DESC")
      .relations(["images", "categories"])
      .paginate(8)
      .all(() => {
        const array: any[] = [];
        this.slider = [];
        this.destacados = [];

        for (let x = 0; x < this.highlighted.items.length; x++) {
          if (x < 4) {
            this.slider.push(this.highlighted.items[x]);
          } else {
            this.destacados.push(this.highlighted.items[x]);
          }
        }

        this.carousel();
      });

    this.medicinas
      .from("articles")
      .where("type", "=", "product")
      .where("highlighted", "=", "true")
      .where('status', '=', '1')
      .orderBy("id", "DESC")
      .relations(["images", "categories"])
      .paginate(6)
      .all()

    this.articulos
      .from("articles")
      .where("highlighted", "=", "false")
      .where("type", "=", "news")
      .orderBy("id", "DESC")
      .relations(["images", "categories"])
      .paginate(8)
      .all()
  },
  methods: {
    carousel() {
      this.rotate();
      this.nextCounter();

      this.timeout = setTimeout(() => {
        this.carousel();

      }, 5000)
    },
    rotate() {
      const articles = document.getElementsByClassName("slider-item");

      for (var i = 0; i < articles.length; i++) {
        articles.item(i)?.classList?.add("d-none");
      }

      document.getElementById(`slider-item-${this.counter}`)?.classList?.remove('d-none');
      document.getElementById(`slider-item-${this.counter}`)?.classList?.add('d-block');
    },
    resetTimeout() {
      clearTimeout(this.timeout);
    },
    nextCounter() {
      this.counter++

      if (this.counter >= document.getElementsByClassName("slider-item").length) {
        this.counter = 0;
      }
    },
    prevCounter() {
      this.counter--

      if (this.counter < 0) {
        this.counter = document.getElementsByClassName("slider-item").length - 1;
      }
    },
    next() {
      this.resetTimeout();
      this.nextCounter();
      this.rotate();
      this.carousel();
    },
    prev() {
      this.resetTimeout();
      this.prevCounter();
      this.rotate();
      this.carousel();
    }
  },
  unmounted() {
    this.resetTimeout()
  }
})
