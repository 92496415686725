<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/admin" class="brand-link elevation-4">
      <img src="@/assets/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">Menú</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="info">
          <a href="#" class="d-block">{{ access?.user?.name }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <!--
          <li class="nav-item">
            <router-link :to="{ name: 'admin.home' }" class="nav-link">
              <p>
                Inicio
              </p>
            </router-link>
          </li>
          -->
          <!-- Articles -->
          <li
            class="nav-item"
            :class="sidebar.open === 'articles' && 'menu-open'"
            v-if="hasCrudPermission(['articles', 'news', 'categories', 'tags'])"
          >
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'articles' && 'active'"
              @click.prevent="collapse('articles')"
            >
              <font-awesome-icon :icon="sidebar.open === 'articles' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>Artículos</p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item"
                v-if="hasCrudPermission(['articles'])"
              >
                <router-link :to="{ name: 'admin.articles.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p> Medicinas</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="hasCrudPermission(['news'])"
              >
                <router-link :to="{ name: 'admin.news.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Noticias</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="hasCrudPermission(['categories'])"
              >
                <router-link :to="{ name: 'admin.categories.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Categorías</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="hasCrudPermission(['tags'])"
              >
                <router-link :to="{ name: 'admin.tags.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Etiquetas</p>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- Sites -->
          <li
            class="nav-item"
            :class="sidebar.open === 'sites' && 'menu-open'"
            v-if="hasCrudPermission(['states', 'organizations', 'sites'])"
          >
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'sites' && 'active'"
              @click.prevent="collapse('sites')"
            >
              <font-awesome-icon :icon="sidebar.open === 'sites' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>Farmacia</p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item"
                v-if="hasCrudPermission(['states'])"
              >
                <router-link :to="{ name: 'admin.states.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Estados</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="hasCrudPermission(['organizations'])"
              >
                <router-link :to="{ name: 'admin.organizations.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Instituciones</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="hasCrudPermission(['sites'])"
              >
                <router-link :to="{ name: 'admin.sites.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Sedes</p>
                </router-link>
              </li>
            </ul>
          </li>
            <hr>
            <li
              class="nav-header"
              v-if="hasCrudPermission([
                'users',
                'roles',
                'permissions',
                'contacts',
                'settings'
              ])"
            >
              OPCIONES
            </li>
          <!-- Access -->
          <li
            class="nav-item"
            :class="sidebar.open === 'access' && 'menu-open'"
            v-if="hasCrudPermission(['users', 'roles', 'permissions'])"
          >
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'access' && 'active'"
              @click.prevent="collapse('access')"
            >
              <font-awesome-icon :icon="sidebar.open === 'access' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>
                Acceso
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li
                class="nav-item"
                v-if="hasCrudPermission(['users'])"
              >
                <router-link :to="{ name: 'admin.access.users.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Usuarios</p>
                </router-link>
              </li>
              <!-- -->
              <li
                class="nav-item"
                v-if="hasCrudPermission(['roles'])"
              >
                <router-link :to="{ name: 'admin.access.roles.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Roles</p>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="hasCrudPermission(['permissions'])"
              >
                <router-link :to="{ name: 'admin.access.permissions.index' }" class="nav-link" active-class="active">
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Permisos</p>
                </router-link>
              </li>
              <!-- -->
            </ul>
          </li>
          <!-- Contacts -->
          <li
            class="nav-item"
            v-if="hasCrudPermission(['contacts'])"
          >
            <router-link :to="{ name: 'admin.contacts.index' }" class="nav-link" active-class="active">
              <p>Contactos</p>
            </router-link>
          </li>
          <!-- Settings -->
          <li
            class="nav-item"
            :class="sidebar.open === 'settings' && 'menu-open'"
            v-if="hasCrudPermission(['settings'])"
          >
            <a
              href="#"
              class="nav-link"
              :class="sidebar.open === 'settings' && 'active'"
              @click.prevent="collapse('settings')"
            >
              <font-awesome-icon :icon="sidebar.open === 'settings' ? 'angle-down' : 'angle-left'" class="nav-icon float-right" />
              <p>
                Configuración
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.index' }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Sistema</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.internal', params: {slug: 'contact_us'} }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Contáctanos</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.internal', params: {slug: 'about_us'} }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Acerca de</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.internal', params: {slug: 'mission'} }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Misión</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.internal', params: {slug: 'vision'} }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Visión</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.internal', params: {slug: 'values'} }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Valores</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.settings.internal', params: {slug: 'history'} }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Historia</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.images.index' }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Imágenes</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'admin.preguntas.index' }"
                  class="nav-link"
                  active-class="active"
                  disabled
                >
                  <font-awesome-icon icon="circle" class="nav-icon" />
                  <p>Preguntas Frecuentes</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

</template>

<script>
import { Auth } from '../../../services/auth.service'
export default {
  name: 'Navbar',
  data () {
    return {
      sidebar: {
        open: null
      },
      access: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    collapse: function (name) {
      if (this.sidebar.open === name) {
        this.sidebar.open = null
      } else {
        this.sidebar.open = name
      }
    },
    hasPermission(name) {
      return Auth.hasPermission(name);
    },
    hasCrudPermission(modules) {
      for (const action of ['create', 'read', 'update', 'delete']) {
        for (const module of modules) {
          if (this.hasPermission(`${action}_${module}`)) {
            return true;
          }
        }
      }

      return false;
    }
  }
}
</script>
