
import { defineComponent } from "vue"
import { ActiveRecordService } from "../../helpers/active_record";
import Sidebar from '../../components/public/layouts/Sidebar.vue';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  components : {
    'Sidebar': Sidebar
  },
  data() {
    return {
      model: new ActiveRecordService(),
      categories: new ActiveRecordService(),
      title: 'Noticias',
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  mounted () {
    this.$emit('background', 'bg-none')

    this.categories
      .from('categories')
      .where('type', '=', 'news')
      .orderBy('name', 'ASC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .paginate(0)
      .all()

    this.model
      .from('articles')
      .where('type', '=', 'news')
      .where('status', '=', '1')
      .orderBy('id', 'DESC')
      //.orderBy('highlighted', 'DESC')
      .relations(['images', 'category'])
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .paginate(8)
      .all()
  },
  methods: {
  },
})
