
import { defineComponent } from "vue";
import { ActiveRecordService } from "../../helpers/active_record";
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  components : {
  },
  data() {
    return {
      sites: new ActiveRecordService(),
      states: new ActiveRecordService(),
      organizations: new ActiveRecordService(),
      open: {
        state: '',
        organization: ''
      },
      title: 'Direcciones',
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  mounted () {
    this.$emit('background', 'bg-none')

    this.states
      .from('states')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .orderBy('name')
      .paginate(0)
      .all()
    ;

    this.organizations
      .from('organizations')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .orderBy('name')
      .paginate(0)
    ;

    this.sites
      .from('sites')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .orderBy('id')
      .paginate(0)
    ;
  },
  methods: {
    toggleState(state: any): void {
      if (this.open.state == state.attributes.id) {
        this.open.state = ''
      } else {
        this.organizations
          .where('state_id', '=', state.attributes.id)
          .all(() => {
            this.open.state = state.attributes.id;
          })
      }
    },
    toggleOrganization(organization: any): void {
      if (this.open.organization == organization.attributes.id) {
        this.open.organization = ''
      } else {
        this.sites
          .where('organization_id', '=', organization.attributes.id)
          .all(() => {
            this.open.organization = organization.attributes.id;
          })
      }
    },
  }
})
