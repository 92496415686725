<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Contactos</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Opciones</li>
              <li class="breadcrumb-item">Contactos</li>
              <li class="breadcrumb-item active">Detalle</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Detalle</h3>

                <div class="card-tools">
                  <router-link
                    :to="{ name: 'admin.contacts.index' }"
                    class="btn btn-tool btn-danger"
                  >
                    <font-awesome-icon icon="undo" />
                    Regresar
                  </router-link>
                </div>
              </div>
              <div class="card-body">
                <form class="row">
                  <div class="offset-2 col-4">
                    <label for="name">Nombre: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="name"
                      name="name"
                      placeholder="Nombre"
                      v-model="model.attributes.name"
                      disabled
                    >
                  </div>

                  <div class="col-4">
                    <label for="email">Correo: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="email"
                      name="email"
                      placeholder="Correo"
                      v-model="model.attributes.email"
                      disabled
                    >
                  </div>

                  <div class="offset-2 col-4">
                    <label for="phone">Teléfono: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="phone"
                      name="phone"
                      placeholder="Teléfono"
                      v-model="model.attributes.phone"
                      disabled
                    >
                  </div>

                  <div class="col-4">
                    <label for="organization">Móvil: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="organization"
                      name="organization"
                      v-model="model.attributes.organization"
                      disabled
                    >
                  </div>

                  <div class="form-group offset-2 col-8">
                    <label for="subject">Asunto: </label>
                    <input
                      type="text"
                      class="form-control form-control-border"
                      id="subject"
                      name="subject"
                      v-model="model.attributes.subject"
                      disabled
                    >
                  </div>

                  <div class="form-group offset-2 col-8">
                    <label for="message">Mensaje: </label>
                    <textarea
                      class="form-control form-control-border"
                      id="message"
                      name="message"
                      placeholder="Descripción"
                      v-model="model.attributes.message"
                      disabled
                      rows="10"
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { ActiveRecordService } from '../../../helpers/active_record'

export default {
  data () {
    return {
      model: new ActiveRecordService({
        id: undefined,
        name: undefined,
        email: undefined,
        organization: undefined,
        message: undefined,
      })
    }
  },
  mounted () {
    this.model
      .from('contacts')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .load(this.$route.params.id)
  },
  methods: {
    save() {
      this.$emit('loading', true)

      let success;

      if (this.model.isNew) {
        success = (response) => {
          this.$router.push({ name: 'admin.contacts.index' })
          this.$emit('loading', false)
        }
      } else {
        success = (response) => {
          this.model.load(response.data[this.model.primaryKey])
          this.$emit('loading', false)
        }
      }

      this.model.save(success, () => {
        this.$emit('loading', false)
      })
    }
  }
}
</script>
