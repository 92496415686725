import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap' 
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faGooglePay,
    faWhatsapp,
    faTelegram,
} from '@fortawesome/free-brands-svg-icons'
import {   
    faSignOutAlt,
    faBars,
    faCircle,
    faAngleLeft,
    faAngleDown,
    faPlus,
    faEdit,
    faTrashAlt,
    faSave,
    faBackspace,
    faUndo,
    faHome,
    faSort,
    faSortUp,
    faSortDown,
    faFilter,
    faEye,
    faSearch,
    faMinus,
    faCloudDownloadAlt,
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faWindowClose,
    faReply,
    faRedo,
} from '@fortawesome/free-solid-svg-icons'
import { Multiselect } from 'vue-multiselect'
import { createI18n } from 'vue-i18n'
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import { IonicVue  } from '@ionic/vue';

const messages = {
  es: require('./assets/lang/es.json')
}

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages,
})

const app = createApp(App)
    .use(router)
    //.use(i18n)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAuBGXSoUG-MSgr41i_hVrCww-x7dN6Vzo',
        },
    })
    .use(IonicVue)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('multiselect', Multiselect)


    app.config.globalProperties.$filters = {
        stripHTML(value: string): string {
            let regex = /(<([^>]+)>)/ig;
            return value?.replace(regex, "");
        },
        currency(value: any, ccy = 'Bs.'): any {
            return `${ccy} ${value}`;
        },
        numberFormat(
            number: number,
            decimals = 2,
            dec_point = ',',
            thousands_sep = '.'
        ): string {
            let str = number.toString().split('.');
    
            let parts = [];
    
            for (let i=str[0].length; i>0; i-=3 ) {
                parts.unshift(str[0].substring(Math.max(0,i-3),i));
            }

            str[0] = parts.join(thousands_sep);
            str[1] = (str[1] ? str[1].substring(0, decimals) : '0').padEnd(decimals, '0');
            
            return str.join(dec_point);
        }
    }

library.add(
    faFacebook,
    faTwitter,
    faInstagram,
    faWhatsapp,
    faTelegram,
    faGooglePay,
    faEnvelope,

    faSignOutAlt,
    faBars,
    faCircle,
    faAngleLeft,
    faAngleDown,
    faPlus,
    faMinus,
    faEdit,
    faEye,
    faTrashAlt,
    faSave,
    faBackspace,
    faUndo,
    faHome,
    faSort,
    faSortUp,
    faSortDown,
    faFilter,
    faBars,
    faSearch,
    faCloudDownloadAlt,
    faChevronLeft,
    faChevronRight,
    faWindowClose,
    faRedo,
    faSearch,
);

router.isReady().then(() => {
    app.mount('#app');
});
