
import { defineComponent } from "vue"
import { ActiveRecordService } from "../../helpers/active_record";
import Sidebar from '../../components/public/layouts/Sidebar.vue'
import { isPlatform } from "@ionic/vue";

export default defineComponent({
  components : {
    'Sidebar': Sidebar,
  },
  data() {
    return {
      model: new ActiveRecordService(),
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
      apkLink: "",
    }
  },
  mounted () {
    this.$emit('loading', false)

    this.$emit('background', 'bg-none')

    this.model
      .from('articles')
      .relations(['images'])
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
        this.apkLink = `http://www.farmaciasmoviles.com.ve/productos/${this.model.attributes.id}/${this.model.attributes.slug}`
        document.querySelector("meta[property='og:image']")?.setAttribute("content", this.model.attributes.images?.length > 0 ? this.model.attributes.images[0].path : '/img/products/00.jpeg')
        document.querySelector("meta[property='og:url']")?.setAttribute("content", this.apkLink)
        document.querySelector("meta[property='twitter:image']")?.setAttribute("content", this.model.attributes.images?.length > 0 ? this.model.attributes.images[0].path : '/img/products/00.jpeg')
      })
      .load(this.$route.params.id)
  }
})
