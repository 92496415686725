<template>
  <div id="contact-us" class="container-fluid position-relative pb-5 bg-light">
    <div class="container">
      <div class="row px-md-5 gx-md-5 pb-5">
        <h1 id="content-title" class="text-main pb-5">{{ title }}</h1>
        <h3>{{ settings.attributes.value }}</h3>
        <div class="col-md-8 px-sm-5 row">
          <div class="col-md-6">
            <label for="name">Nombre *</label>
            <input
              id="name"
              name="name"
              class="form-control"
              type="text" 
              v-model="item.name"
              :class="errors['name'] ? 'is-invalid' : ''"
              maxlength="60"
            >
            <template v-if="errors['name']">
              <div
                v-for="error in errors['name']"
                :key="error"
                class="invalid-feedback"
              >
                {{ error }}
              </div>
            </template>
          </div>
          <div class="col-md-6">
            <label for="email">Correo *</label>
            <input
              id="email"
              name="email"
              class="form-control"
              type="email"
              v-model="item.email"
              :class="errors['email'] ? 'is-invalid' : ''"
              maxlength="60"
            >
            <template v-if="errors['email']">
              <div
                v-for="error in errors['email']"
                :key="error"
                class="invalid-feedback"
              >
                {{ error }}
              </div>
            </template>
          </div>
          <div class="col-md-6">
            <label for="phone">Teléfono</label>
            <input
              id="phone"
              name="phone"
              class="form-control"
              type="text" 
              v-model="item.phone"
              :class="errors['phone'] ? 'is-invalid' : ''"
              maxlength="60"
            >
            <template v-if="errors['phone']">
              <div
                v-for="error in errors['phone']"
                :key="error"
                class="invalid-feedback"
              >
                {{ error }}
              </div>
            </template>
          </div>
          <div class="col-md-6">
            <label for="organization">Móvil</label>
            <input
              id="organization"
              name="organization"
              class="form-control"
              type="text" 
              v-model="item.organization"
              :class="errors['organization'] ? 'is-invalid' : ''"
              maxlength="60"
            >
            <template v-if="errors['organization']">
              <div
                v-for="error in errors['organization']"
                :key="error"
                class="invalid-feedback"
              >
                {{ error }}
              </div>
            </template>
          </div>
          <div class="form-group">
            <label for="subject">Asunto</label>
            <input
              id="subject"
              name="subject"
              class="form-control"
              type="text" 
              v-model="item.subject"
              :class="errors['subject'] ? 'is-invalid' : ''"
              maxlength="60"
            >
            <template v-if="errors['subject']">
              <div
                v-for="error in errors['subject']"
                :key="error"
                class="invalid-feedback"
              >
                {{ error }}
              </div>
            </template>
          </div>
          <div class="form-group">
            <label>Mensaje *</label>
            <textarea 
              class="form-control"
              rows="4"
              v-model="item.message"
              :class="errors['message'] ? 'is-invalid' : ''"
              maxlength="1080"
            ></textarea>
            <template v-if="errors['message']">
              <div
                v-for="error in errors['message']"
                :key="error"
                class="invalid-feedback"
              >
                {{ error }}
              </div>
            </template>
          </div>
          <div class="form-group">
            <input id="submit" class="btn bg-main text-light fw-bold px-4 mt-auto mb-3" type="submit" value="Enviar" @click.prevent="send()">
          </div>
        </div>
        <div class="col-sm-4" v-html="settings.attributes.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { ActiveRecordService } from "../../helpers/active_record";
import { isPlatform } from '@ionic/vue';

export default {
  data () {
    return {
      item: {
        name: undefined,
        email: undefined,
        phone: undefined,
        organization: undefined,
        subject: undefined,
        message: undefined
      },
      title: 'Contacto',
      subtitle: '',
      errors: {},
      settings: new ActiveRecordService(),
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  mounted () {
    this.$emit('loading', false)
    this.$emit('background', 'bg-none')

    this.settings
      .from('settings')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .load(12)
  },
  methods: {
    send() {
      this.$emit('loading', true)
      axios.post(`${process.env.VUE_APP_API_URL}/api/contacts`, this.item)
        .then(response => {
          this.$forceUpdate()

          this.errors = {}

          this.item = {
            name: undefined,
            email: undefined,
            organization: undefined,
            message: undefined
          }
          this.$emit('loading', false)

          Swal.fire({
            title: 'Enviado',
            text: 'Sus datos han sido enviados de forma satisfactoria.',
            icon: 'success',
            confirmButtonText: 'Cerrar'
          })
        })
        .catch(error => {
          this.errors = error.response?.data?.errors || {}
          this.$emit('loading', false)
        })
    }
  },
}
</script>
