
import { defineComponent } from "vue"
import { ActiveRecordService } from "../../../helpers/active_record";

export default defineComponent({
  props: ['model'],
  data() {
    return {
      categories: new ActiveRecordService(),
    }
  },
  mounted() {
    this.categories
      .from('categories')
      .orderBy('name', 'ASC')
      .setBefore(() => {
        this.$emit('loading', true)
      })
      .setAfter(() => {
        this.$emit('loading', false)
      })
      .setPerPage(0)
      .all()
  },
  methods: {
    changed(event: any) {
      
    },
    search() {
      this.model.all();
    }
  },
})
