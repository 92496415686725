
import { defineComponent } from "vue"
import { ActiveRecordService } from "../../helpers/active_record";
import Sidebar from '../../components/public/layouts/Sidebar.vue'
import { isPlatform } from "@ionic/vue";

export default defineComponent({
  components : {
    'Sidebar': Sidebar,
  },
  data() {
    return {
      model: new ActiveRecordService(),
      isWeb: !isPlatform('hybrid') && !isPlatform('mobileweb'),
    }
  },
  mounted () {
    this.$emit('loading', false)
  }
})
