
import axios from 'axios';
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router';
export default defineComponent({
  data () {
    return {
      form: {
        email: null,
        password: null
      },
      errors: []
    }
  },
  methods: {
    login() {
      this.$emit('loading', true)
      axios.post(`${process.env.VUE_APP_API_URL}/api/login`, this.form)
      .then(response => {
        this.$emit('loading', false)

        localStorage.user = JSON.stringify(response.data)

        this.$router.push({ name: 'admin.articles.index' })
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error.response?.data?.errors);
        this.errors = error.response?.data?.errors;
      })
    }
  },
  created () {
    this.$emit('loading', false)
    if (localStorage.user) {
      this.$router.push({ name: 'admin.articles.index' })
    }
  }
})
