
import { defineComponent } from "vue"
import { Collapse } from 'bootstrap';
import { isPlatform } from "@ionic/vue";

export default defineComponent({
  data() {
    return {
      logo:`${process.env.VUE_APP_API_URL}/public/files/image-logo`,
    }
  },
  mounted() {
  },
  methods: {
    search(value: string) {
      if (value) {
        window.location.replace(`/productos?title=${value}`);
      }
    },
    collapse() {
      if (isPlatform('hybrid') || isPlatform('mobileweb')) {
        new Collapse('#navbar')
      }
    },
  }
})
