<template>
  <div id="not-found" class="container-fluid p-5">
    <div class="container">
      <div class="row p-5">
        <div class="offset-1 col-10 px-5 text-center">
          <h1>Página no encontrada</h1>
          <h3>404</h3>
          <router-link :to="{ name: 'home' }">Volver al inicio</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$emit('background', 'bg-none')
    this.$emit('loading', false)
  }
}
</script>